/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.checkbox-epc-green {
  color: #8AB7B2;
}

.checkbox-epc-red {
  color: #D46262;
}
